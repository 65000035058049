/* You can add global styles to this file, and also import other style files */
.project-container {
    width: 100%;
    height: calc(100vh - 3rem);
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
}

.ab-project-container {
    height: calc(100vh - 88px);
    background-color: #F5F7FA
}

.project-container-no-navigation {
    height: 100vh;
    display: block;
}

.project-container-white-background {
    background-color: #FFF;
}

.deployment-defaults-container {
    width: 100%;
    height: calc(100vh - 2rem);
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
}

// global set cke_combo_text width auto, so custom plugin
// will not be cut off
.cke_combo_text {
    width: auto !important;
}

@mixin icons-class($size) {
    background-size: contain;
    background-repeat: no-repeat;
    height: $size;
    width: $size;
    min-height: $size;
    min-width: $size;
}

@mixin skittles-icon($url) {
    @include icons-class(16px);
    background-image: url($url);
}

.skittles-text-search-enabled-icon {
    @include skittles-icon("assets/text-search.png");
}

.skittles-olythics-enabled-icon {
    @include skittles-icon("assets/olytics-search.png");
}

.skittles-multi-select-enabled-icon {
    @include skittles-icon("assets/multi-select.png");
}

.skittles-single-select-enabled-icon {
    @include skittles-icon("assets/single-select.png");
}

.skittles-boolean-enabled-icon {
    @include skittles-icon("assets/yes-no-search.png");
}

.skittles-onq-selections-enabled-icon {
    @include skittles-icon("assets/query-search.png");
}

.skittles-behaviors-enabled-icon {
    @include skittles-icon("assets/behavior-search.png");
}

.skittles-memo-enabled-icon {
    @include skittles-icon("assets/memo-search.png");
}

.skittles-paid-enabled-icon {
    @include skittles-icon("assets/paid-icon-enabled.png");
}

.skittles-paid-icon-selected {
    @include skittles-icon("assets/paid-icon-selected.png");
}

.skittles-text-search-enabled-icon-selected {
    @include skittles-icon("assets/text-selected.png");
}

.skittles-olythics-enabled-icon-selected {
    @include skittles-icon("assets/olytics-selected.png");
}

.skittles-multi-select-enabled-icon-selected {
    @include skittles-icon("assets/multiple-selected.png");
}

.skittles-single-select-enabled-icon-selected {
    @include skittles-icon("assets/single-selected.png");
}

.skittles-boolean-enabled-icon-selected {
    @include skittles-icon("assets/yes-no-selected.png");
}

.skittles-onq-selections-enabled-icon-selected {
    @include skittles-icon("assets/query-selected.png");
}

.skittles-behaviors-enabled-icon-selected {
    @include skittles-icon("assets/behavior-selected.png");
}

.skittles-memo-enabled-icon-selected {
    @include skittles-icon("assets/memo-selected.png");
}


.icon-width {
    width: 2rem;
    height: 2rem;
    color: var(--_enso_color_blue_600);
}

@mixin integration-db-icons($url) {
    @include icons-class(40px);
    background-image: url($url);
}


.delta-icon {
    @include integration-db-icons("assets/delta.png");
}

.meta-icon {
    @include integration-db-icons("assets/meta-icon.svg");
}

.adroll-icon {
    @include integration-db-icons("assets/adroll.png");
}

.google-ad-icon {
    @include integration-db-icons("assets/google-ad.png");
}

.analytics-icon {
    @include integration-db-icons("assets/analytics.svg");
}

.blueconinc-icon {
    @include integration-db-icons("assets/blueconic.png");
}

.credspark-icon {
    @include integration-db-icons("assets/credspark.png");
}

.shopify-icon {
    @include integration-db-icons("assets/shopify.svg");
}

.google-icon {
    @include icons-class(1.25rem);
    background-image: url("assets/google-icon.svg");
    background-color: #F5F7FA;
}

.sad-icon {
    @include icons-class(100px);
    background-image: url("assets/sad-icon.svg");
    background-color: #F5F7FA;
}

.step-stroke {
    border: 1px solid #E2E2E2;
    margin: 24px 0px;
}

@media screen and (min-width: 2160px) {
    .steps-shell-container {
        margin: 24px 48px 0px 48px;
    }
}

@media screen and (min-width: 1768px) and (max-width: 2159px) {
    .steps-shell-container {
        margin: 24px 40px 0px 40px;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1767px) {
    .steps-shell-container {
        margin: 24px 32px 0px 32px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .steps-shell-container {
        margin: 24px 24px 0px 24px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .steps-shell-container {
        margin: 24px 24px 0px 24px;
    }
}

@media screen and (max-width: 767px) {
    .steps-shell-container {
        margin: 24px 16px 24px 16px;
    }
}
